<template>
  <div>
    <progress-shimmer v-if="loaderAutoDayOut" />
    <div
      v-else
      class="total-project-hour-dashboard hrinoutinfo mxHeight"
    >
      <div class="d-flex align-items-start justify-content-between hrFlatpicker">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>Not Day in</span> Today
          </h3>
        </div>
      </div>
      <div class="all-project-progress hr-dashboard">
        <ul class="p-0 m-0">
          <li>
            <table>
              <tbody>
                <tr
                  v-for="(user,index) in filterUserList"
                  :key="index"
                >
                  <td class="empName">
                    <div
                      v-if="user.full_name"
                      class="d-flex align-items-center"
                    >
                      <b-avatar
                        :src="user.picture"
                        :text="user.full_name | avatarText"
                        :variant="randomVariant(1, filterUserList.length)"
                        class="mr-50"
                      />
                      <p
                      class="tbltxtWrap cursor-pointer"
                      @click="openUserProfile(user.id)"
                      >
                        {{ user.full_name }}
                      </p>
                    </div>
                  </td>
                  <td class="empEmail">
                    <div
                      v-if="user.email"
                      class="d-flex align-items-center "
                    >
                      <span>
                        <feather-icon
                          icon="MailIcon"
                          width="17"
                          height="17"
                          class="mr-50"
                        />
                      </span><p v-b-tooltip.hover.top="user.email">
                        {{ user.email }}
                      </p>
                    </div>
                  </td>
                  <td class="empPhn">
                    <div
                      v-if="user.phone"
                      class="d-flex align-items-center"
                    >
                      <span>
                        <feather-icon
                          icon="PhoneIcon"
                          width="17"
                          height="17"
                          class="mr-50"
                        />
                      </span>{{ user.phone }}
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-center"
                    >
                      <span>-</span>
                    </div>
                  </td>

                  <td
                    v-if="user.lastout && user.lastout.last_out && user.lastout.last_out_date"
                    class="emplstcol"
                  >
                    Last day out at {{ user.lastout.last_out | timeFormat }}
                    <span class="d-block">on {{ user.lastout.last_out_date | DDMMYYYYDDDD }}</span>
                  </td>
                  <td
                    v-else
                    class="emplstcol"
                  >
                    <span>No Activity</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>

        </ul>
      </div>

      <div
        v-if="filterUserList && !filterUserList.length"
        class="d-flex align-items-center"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img no-data-dashboard"
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ProgressShimmer from '../../../views/shimmer/ProgressShimmer.vue'

export default {

  name: 'NotDayIn',
  components: {
    ProgressShimmer,
  },
  data() {
    return {
      filterUserList: [],
      loaderAutoDayOut: false,
      date: null,
    }
  },

  watch: {
    date() {
      this.autoDayOut()
    },

  },
  mounted() {
    this.date = moment().format('YYYY-MM-DD')
  },
  methods: {

    async autoDayOut() {
      this.loaderAutoDayOut = true

      const input = {
        date: this.date,
      }

      const response = await this.getHTTPPostResponse(
        '/hr/dashboard/userList',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.filterUserList = data.notLoginUsers
        this.loaderAutoDayOut = false
      }
    },
  },

}
</script>
