<template>
  <div class="">
    <progress-shimmer v-if="loaderAutoDayOut" />
    <div
      v-else
      class="total-project-hour-dashboard hrinoutinfo newMxHeight"
    >
      <div
        class="d-flex align-items-center justify-content-between hrFlatpicker"
      >
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>Less than 8 hours</span> {{ date | formatDate }}
          </h3>
        </div>
        <div class="hrDaytime">
          <b-input-group class="color-picker-input">
            <flat-pickr
              v-model="date"
              :config="dateDayRangeConfig"
              class="form-control hrcalIcon"
            />
          </b-input-group>
        </div>
      </div>
      <div class="all-project-progress hr-dashboard">
        <ul
          v-if="filterUserList && filterUserList.length"
          class="p-0 m-0"
        >
          <li>
            <table>
              <tbody>
                <tr
                  v-for="(user, index) in filterUserList"
                  :key="index"
                >
                  <td class="empName">
                    <div
                      v-if="user.full_name"
                      class="d-flex align-items-center"
                    >
                      <b-avatar
                        :src="user.picture"
                        :text="user.full_name | avatarText"
                        :variant="randomVariant(1, filterUserList.length)"
                        class="mr-50"
                      />
                      <p
                        class="tbltxtWrap cursor-pointer"
                        @click="openUserProfile(user.id)"
                      >
                        {{ user.full_name }}
                      </p>
                    </div>
                  </td>
                  <td class="empEmail">
                    <div
                      v-if="user.email"
                      class="d-flex align-items-center "
                    >
                      <span>
                        <feather-icon
                          icon="MailIcon"
                          width="17"
                          height="17"
                          class="mr-50"
                        />
                      </span>
                      <p v-b-tooltip.hover.top="user.email">
                        {{ user.email }}
                      </p>
                    </div>
                  </td>
                  <td class="empPhn">
                    <div
                      v-if="user.phone"
                      class="d-flex align-items-center"
                    >
                      <span>
                        <feather-icon
                          icon="PhoneIcon"
                          width="17"
                          height="17"
                          class="mr-50"
                        /> </span>{{ user.phone }}
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-end justify-content-center"
                    >
                      <span>-</span>
                    </div>
                  </td>
                  <td class="emplstcol">
                    Total clocked Hours
                    <span class="d-block">{{ totalMinute(user.clock) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>

      <div
        v-if="filterUserList && !filterUserList.length"
        class="d-flex align-items-center"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img no-data-dashboard"
        >
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import ProgressShimmer from '../../../views/shimmer/ProgressShimmer.vue'

export default {
  name: 'NotDayIn',
  components: {
    flatPickr,
    ProgressShimmer,
  },

  data() {
    return {
      filterUserList: [],
      loaderAutoDayOut: false,
      date: null,
      dateDayRangeConfig: {
        dateFormat: 'd-m-Y',
        mode: 'single',
        maxDate: new Date(),
      },
    }
  },
  watch: {
    date(nv, old) {
      if (!nv) {
        this.date = old
      }
      this.autoDayOut()
    },
  },
  mounted() {
    if (moment().weekday() == 0) {
      this.date = moment().subtract(2, 'days').format('DD-MM-YYYY')
    } else if (moment().weekday() == 1) {
      this.date = moment().subtract(3, 'days').format('DD-MM-YYYY')
    } else {
      this.date = moment().subtract(1, 'days').format('DD-MM-YYYY')
    }
  },
  methods: {
    totalMinute(data) {
      let total = 0
      if (data && data.length) {
        data.forEach(element => {
          total += element.time_diff
        })
      }

      return this.secondsToTime(total)
    },

    /**
     * Get the login and filter users activty logs
     * @return logs
     */
    async autoDayOut() {
      this.loaderAutoDayOut = true

      const input = {
        date: moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }
      if (input.date != 'Invalid date') {
        const response = await this.getHTTPPostResponse(
          '/hr/dashboard/hoursFilterUserList',
          input,
          false,
        )
        if (response && response.data) {
          const { data } = response
          this.filterUserList = data.filterUserList
        }
      }
      this.loaderAutoDayOut = false
    },
  },
}
</script>
