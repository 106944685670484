<template>
  <div>
    <b-card
      no-body
      class="card-dashboard-section"
    >
      <b-card-header class="dashboard-header">
        <b-card-title>
          <div class="dashbord-nav">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span>Dashboard </span>
            <button
              v-if="
                userInfo && (userInfo.role == 'SA' || userInfo.role == 'HR')
              "
              v-b-tooltip.hover.bottom="
                'Last sync done by ' + user_name + ' on ' + last_sync_timestamp
              "
              type="button"
              class="btn ml-1 syncBtn"
              :disabled="button_loader"
              @click="sync()"
            >
              <i class="bx bx-sync" />
              <b-icon icon="arrow-repeat" />
            </button>
          </div>
        </b-card-title>
        <Help
          v-show="$store.state.app.userManualList"
          code="HR"
        />
      </b-card-header>
    </b-card>
    <Clock
      :user-count="$store.state.app.usersList.length"
    />
  </div>
</template>

<script>
import Clock from '../@core/components/clockInOut/index.vue'

export default {
  components: {
    Clock,
  },

  data() {
    return {
      users: [],
      data: null,
      userCount: null,
      load_activity: false,
      activityLogs: [],
      last_sync_timestamp: null,
      user_name: null,
      button_loader: false,
    }
  },

  mounted() {
    this.lastSync()
    if (this.$store.state.app.usersList.length == 0) {
      this.users = this.userList()
    } else {
      this.users = this.$store.state.app.usersList
    }
    this.userCount = this.users.length
  },
  methods: {
    // sync data
    async sync() {
      this.button_loader = true
      const response = await this.getHTTPGetResponse(
        'admin/master/auth-sync',
        {},
        true,
      )
      if (response && response.data) {
        this.lastSync()
        this.button_loader = false
      }
    },

    // get last sync timestamp data
    async lastSync() {
      const response = await this.getHTTPGetResponse(
        'admin/master/log/sync-timestamp',
        {},
      )
      if (response && response.data) {
        const { data } = response
        this.last_sync_timestamp = data.sync_log_timestamp.value
        this.user_name = `${data.sync_log_timestamp.updated_by.name} ${data.sync_log_timestamp.updated_by.last_name}`
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.no-data-dashboard {
  width: 50%;
}
</style>
