<template>
  <div class="hrdashboardInfo">
    <b-row class="mx-0">
      <b-col
        cols="12 p-0"
        lg="5"
        xl="3"
      >
        <hruser-shimmer v-if="load_activity" />
        <!-- Total count user block-->
        <div
          v-else
          class="
            total-project-hour-dashboard
            hr-total-user
            text-center
            hrEmpinfo
            mxHeight
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <div class="">
            <p class="user-icon">
              <feather-icon
                icon="UsersIcon"
                width="72"
                height="72"
              />
            </p>
            <p class="card-title">
              Total User Count
            </p>
            <p class="user-count">
              {{ userCount }}
            </p>
          </div>
        </div>
      </b-col>
      <!-- in out activity -->
      <b-col
        cols="12 p-0 pl-lg-2 pt-lg-0 pt-2"
        lg="7"
        xl="3"
      >
        <hruser-shimmer v-if="load_inout_activity" />
        <div
          v-else
          class="
            total-project-hour-dashboard
            hrinoutinfo
            mxHeight
            hruseractivity
            overflow-hidden
          "
        >
          <!-- v-else -->
          <div class="hrFlatpicker pb-50">
            <h3 class="mb-0 total-hours-time">
              Today’s In - Out Activity
            </h3>
            <div class="d-flex justify-content-between hrsearchDrop">
              <!-- class="hrSearchbox" -->
              <div>
                <b-input-group class="input-group-merge search-project">
                  <b-form-input
                    v-model="debouncedSearch"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                    style="height: 30px;"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      v-if="search"
                      icon="XIcon"
                      @click="search = null"
                    />
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
                </b-input-group>

              </div>
              <div>
                <b-form-select
                  v-model="perPage"
                  :options="['25', '50', '100', 'All']"
                  class="hrinoutdropdown"
                />
              </div>
            </div>
          </div>

          <div
            v-if="activityLogs.length"
            class="all-project-progress hr-dashboard inoutHeight"
          >
            <ul class="m-0 p-0 float-left w-100">

              <li
                v-for="(log, lindex) in activityLogs"
                :key="lindex"
                class=""
                style="border: none"
              >
                <div class="d-flex">
                  <b-avatar
                    :text="log.name.split(' ').slice(0, 2).join(' ') | avatarText
                    "
                    :variant="randomVariant(1, activityLogs.length)"
                    class="mr-1"
                  />
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      w-100
                    "
                  >
                    <div class="activityName">
                      {{ log.name }}
                      <b-card-text class="">
                        {{ log.status }}

                        <span class="text-lowercase">{{ log.at | timeFormat }}</span>
                      </b-card-text>
                    </div>
                    <div class="activityHours">
                      <p class="user-time-log-dashboard">
                        {{ log.created_at | dateFromNow }}
                      </p>

                    </div>
                  </div>
                </div>

              </li>
            </ul>
          </div>
          <div
            v-if="!activityLogs.length"
            class="d-flex align-items-center"
          >
            <img
              src="@/assets/images/nodatafound/nodata.svg"
              alt="no-data"
              class="no-data-img no-data-dashboard"
            >
          </div>

          <b-col
            v-if="activityLogs && !activityLogs.length"
            cols="12"
            class="
              d-flex
              justify-content-end
              flex-wrap
              align-items-center
              table-pagination
            "
          />
          <div class="d-flex align-items-center">
            <img
              v-if="!load_inout_activity && activityLogs && !activityLogs.length"
              src="@/assets/images/nodatafound/nodata.svg"
              alt="no-data"
              class="no-data-img no-data-dashboard"
            >
          </div>
        </div>
      </b-col>
      <!-- Not day in today -->
      <b-col
        cols="12 p-0 pl-xl-2 pl-0 pt-xl-0 pt-2"
        lg="6"
        xl="6"
      >
        <NotDayIn />
      </b-col>
      <!-- Auto Day Out card -->
      <b-col
        cols="12 p-0 pt-2 pl-lg-2 pl-xl-0"
        lg="6"
        xl="6"
      >
        <AutoDayOut />
      </b-col>
      <b-col
        cols="12 p-0 pt-2 pl-xl-2 pl-0"
        lg="6"
        xl="6"
      >
        <LessEightHr />
      </b-col>

    </b-row>
  </div>
</template>

<script>

import NotDayIn from './NotDayIn.vue'
import AutoDayOut from './AutoDayOut.vue'
import LessEightHr from './LessEightHr.vue'
import HruserShimmer from '../../../views/shimmer/HruserShimmer.vue'
import debounce from 'lodash/debounce'

export default {
  components: {
    NotDayIn,
    AutoDayOut,
    LessEightHr,
    HruserShimmer,
  },

  props: {
    /**
     * For Action list
     * @type {Array}
     */
    userCount: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      load_activity: false,
      load_inout_activity: false,
      activityLogs: [],
      perPage: 25,
      currentPage: 1,
      search: null,
    }
  },
  watch: {
    perPage() {
      this.userActivityLogs()
    },
    search() {
      this.userActivityLogs()
    },
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
      }, 500),
    },
  },
  mounted() {
    this.userActivityLogs()
  },
  methods: {
    async userActivityLogs() {
      // this.load_inout_activity = true;
      const input = {
        page: this.currentPage,
        per_page: this.perPage == 'All' ? null : this.perPage,
        user_id: this.userId,
        search: this.search,
        role: this.userInfo && this.userInfo.role == 'SA' ? 'SA' : '',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/log',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.activityLogs = data.activity

        data.activity.forEach(element => {
          element.status = element.description.toLowerCase().includes('out')
            ? 'clock Out'
            : 'clock In'
          element.name = element.description.toLowerCase().includes('updated')
            ? element.description.split(' ').slice(3, 5).join(' ')
            : element.description.split(' ').slice(0, 2).join(' ')
          element.name = element.name.split("'").slice(0, 1).join(' ')
          element.logUpdatedBy = element.description
            .toLowerCase()
            .includes('updated')
            ? element.description.split(' ').slice(0, 2).join(' ')
              == element.name
              ? null
              : element.description.split(' ').slice(0, 2).join(' ')
            : null
          element.at = element.description.split(' ')[
            element.description.split(' ').length - 1
          ] == 'user'
            ? ''
            : element.description.split(' ')[
              element.description.split(' ').length - 1
            ]
        })
        this.load_inout_activity = false
      }
    },
  },
}
</script>

<style lang="scss">
.hrshimmer {
  height: 300px !important;
}
</style>
