<template>
  <div class="">
    <progress-shimmer v-if="loaderAutoDayOut" />
    <div
      v-else
      class="total-project-hour-dashboard hrinoutinfo autoDayout mxHeight"
    >
      <div class="d-flex align-items-center justify-content-between hrFlatpicker">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>Auto Day out on</span>  {{ date | formatDate }}
          </h3>
        </div>
        <div class="hrDaytime">
          <b-input-group class="color-picker-input">
            <flat-pickr
              v-model="date"
              :config="dateDayRangeConfig"
              class="form-control hrcalIcon"
            />
          </b-input-group>
        </div>
      </div>
      <div class="all-project-progress hr-dashboard">
        <ul
          v-if="autoDayOutUsers && autoDayOutUsers.length"
          class="p-0 m-0"
        >
          <li>
            <table>
              <tbody>
                <tr
                  v-for="(user,index) in autoDayOutUsers"
                  :key="index"
                >
                  <td class="empName">
                    <div
                      class="d-flex align-items-center "
                    >
                      <b-avatar
                        :src="user.user.picture"
                        :text="user.user.full_name | avatarText"
                        :variant="randomVariant(1, autoDayOutUsers.length)"
                        class="mr-50"
                      />
                      <p

                        class="tbltxtWrap cursor-pointer"
                        @click="openUserProfile(user.user.id)"
                      >
                        {{ user.user.full_name }}
                      </p>
                    </div>
                  </td>
                  <td class="empEmail">
                    <div class="d-flex align-items-center">
                      <span>
                        <feather-icon
                          icon="MailIcon"
                          width="17"
                          height="17"
                          class="mr-50"
                        />
                      </span><p v-b-tooltip.hover.top="user.user.email">
                        {{ user.user.email }}
                      </p>
                    </div>
                  </td>
                  <td class="empPhn">
                    <div
                      v-if="user.user.phone"
                      class="d-flex align-items-end justify-content-end"
                    >
                      <span>
                        <feather-icon
                          icon="PhoneIcon"
                          width="17"
                          height="17"
                          class=""
                        />
                        {{ user.user.phone }}
                      </span>

                    </div>
                    <div
                      v-else
                      class="d-flex align-items-end justify-content-center"
                    >
                      -

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>

        </ul>
      </div>

      <div
        v-if="autoDayOutUsers && !autoDayOutUsers.length"
        class="d-flex align-items-center"
      >
        <img
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img no-data-dashboard"
        >
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import ProgressShimmer from '../../../views/shimmer/ProgressShimmer.vue'

export default {
  name: 'AutoDayOut',
  components: {
    flatPickr,
    ProgressShimmer,
  },
  data() {
    return {
      date: null,
      autoDayOutUsers: [],
      loaderAutoDayOut: false,
      dateDayRangeConfig: {
        dateFormat: 'd-m-Y',
        mode: 'single',
        maxDate: new Date(),
      },
    }
  },

  watch: {
    date(nv, old) {
      if (!nv) {
        this.date = old
      }
      this.autoDayOut()
    },
  },
  mounted() {
    if (moment().weekday() === 0) {
      this.date = moment().subtract(2, 'days').format('DD-MM-YYYY')
    } else if (moment().weekday() === 1) {
      this.date = moment().subtract(3, 'days').format('DD-MM-YYYY')
    } else {
      this.date = moment().subtract(1, 'days').format('DD-MM-YYYY')
    }
  },
  methods: {

    /**
     * Get the login and filter users Activity logs
     * @return logs
     */
    async autoDayOut() {
      this.loaderAutoDayOut = true

      const input = {
        date: moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }
      if (input.date != 'Invalid date') {
        const response = await this.getHTTPPostResponse(
          'hr/dashboard/autoDayOutUserList',
          input,
          false,
        )
        if (response && response.data) {
          const { data } = response
          this.autoDayOutUsers = data
        }
      }
      this.loaderAutoDayOut = false
    },
  },
}
</script>
